<template>
  <div class="page" :class="{'is-mobile' : $vuetify.breakpoint.mobile}">
    <div class="content">
      <v-container fluid>
        <div class="ma-2">
          <span class="titulo">Olá, {{userName}},</span>
          <br/>
          <span class="sub-titulo">Como você está se sentindo hoje?</span>
        </div>
        <div v-if="!this.isFornecedorOuComprador(this.nivelUsuarioLogado)" class="ma-2">
          <v-row>
            <v-col cols="12" sm="6">
              <CardPersonalizado :loading="isLoadingG1">
                <template v-slot:title>
                  <v-row>
                    <v-col cols="12" sm="12" class="pa-5 titulo-grafico">
                      Total de Fornecedores Participantes
                    </v-col>
                  </v-row>
                </template>
                <div id="chart-container" class="chart-wrapper">
                  <apexchart ref="grafico1"
                    type="donut"
                    height="350"
                    :options="grafico1.chartOptions"
                    :series="grafico1.series"
                  />              
                </div>
              </CardPersonalizado>
            </v-col>
            <v-col cols="12" sm="6">
              <CardPersonalizado :loading="isLoadingG2">
                <template v-slot:title>
                  <v-row>
                    <v-col cols="12" sm="12" class="pa-5 titulo-grafico">
                      Total de Compradores Participantes
                    </v-col>
                  </v-row>
                </template>
                <div id="chart-container" class="chart-wrapper">
                  <apexchart ref="grafico2"
                    type="radialBar"
                    height="350"
                    :options="grafico2.chartOptions"
                    :series="grafico2.series"
                  />
                </div>
              </CardPersonalizado>
            </v-col>
            <v-col cols="12" sm="12">
              <CardPersonalizado :loading="isLoadingG3">
                <template v-slot:title>
                  <v-row>
                    <v-col cols="12" sm="12" class="pa-5 titulo-grafico">
                      Demandas criadas x Match Realizado
                    </v-col>
                  </v-row>
                </template>
                <apexchart ref="grafico3"
                  type="area"
                  height="350"
                  :options="grafico3.chartOptions"
                  :series="grafico3.series"
                />
              </CardPersonalizado>
            </v-col>
          </v-row>
        </div>
        <div v-else class="ma-2">
          <v-expansion-panels
            v-model="panel"
            multiple expanded>
            <v-expansion-panel :key="0" class="ma-2 rounded-border">
              <v-expansion-panel-header class="titulo">
                {{mensagemDemandasEmAtendimento}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <DeuMatchPainelDataTable ref="demandasEmAtendimento"
                  :search-values="{
                    itemPorPagina: 12
                  }"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :key="1" class="ma-2 rounded-border">
              <v-expansion-panel-header class="titulo">
                {{mensagemDemandasSemAtendimento}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <DemandaPainelDataTable ref="demandasSemAtendimento"
                  @onMatch="() => $refs.demandasEmAtendimento.refresh()"
                  :search-values="{
                    tipoBusca: 'DISPONIVEL',
                    itemPorPagina: 12
                  }"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  findGraficoFornecedor,
  findGraficoComprador,
  findGraficoDemandaVsMatch,
} from "../../api/painel";
import DemandaPainelDataTable from "../../components/shared/datatables/DemandaPainelDataTable";
import DeuMatchPainelDataTable from "../../components/shared/datatables/DeuMatchPainelDataTable";
import CardPersonalizado from "../../components/shared/common/CardPersonalizado";
import {ApiError} from "../../api/core";
import {mapGetters} from "vuex";

export default {
  name: "Painel",
  components: {DemandaPainelDataTable, DeuMatchPainelDataTable, CardPersonalizado},
  data() {
    return {
      isLoadingG1: false,
      isLoadingG2: false,
      isLoadingG3: false,
      graficoAtual: "",
      panel: [0,1],
      grafico1: {
        series: [],
        chartOptions: {
          chart: {
            width: 420,
            type: 'donut',
          },
          dataLabels: {
            enabled: true
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center'
          },
          tooltip: {
            formatter: function(value) {
              return value ? value.toString() : 'N/A';  // Garantir que o valor seja convertido para string
            }
          },
          labels: [], 
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
            }
          }]
        },
      },
      grafico2: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '30%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: true,
                },
                value: {
                  show: true,
                }
              },
              // barLabels: {
              //   enabled: true,
              //   useSeriesColors: true,
              //   offsetX: -8,
              //   fontSize: '12px',
              //   formatter: function(seriesName, opts) {
              //     return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
              //   },
              // },
            }
          },
          stroke: {
            lineCap: 'round'
          },
          colors: [],
          labels: [],
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center'            
          },
          tooltip: {
            formatter: function(value) {
              return value ? value.toString() : 'N/A';  // Garantir que o valor seja convertido para string
            }
          },          
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                show: false
              }
            }
          }]
        },        
      },
      grafico3: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            },             
          },     
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Período'
            }
          },
          yaxis: {
            title: {
              text: 'Quandidade'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
          }
        },
      }
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated', 'nivelUsuarioLogado']),
    userName: {
      get() {
        return this.isFornecedorOuComprador(this.nivelUsuarioLogado) ? this.user?.nomeResponsavel : this.user?.nome;
      }
    },
    mensagemDemandasEmAtendimento(){
      return (this.isFornecedorOuComprador(this.nivelUsuarioLogado) ? "Minhas " : "") + "Demandas em negociação";
    },
    mensagemDemandasSemAtendimento(){
      return (this.nivelUsuarioLogado === 'COM' ? "Minhas " : "") + "Demandas disponíveis para negociação";
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      try {
        this.error = null;
        let page = null;
        if (!this.isFornecedorOuComprador(this.nivelUsuarioLogado)) {
          
          this.isLoadingG1 = true;

          this.graficoAtual = "Fornecedores Participantes";
          page = await findGraficoFornecedor(1, {});
          this.elements = page.elements; 
          this.grafico1.series = this.elements.map(item => item.quantidade || 0);
          this.grafico1.chartOptions.labels = this.elements.map(item => item.descricao || '');  
          this.grafico1.chartOptions.tooltip = {
            enabled: false
          };
          this.$refs.grafico1.updateOptions({
            ...this.grafico1.chartOptions,
            series: this.grafico1.series
          }, true);

          this.isLoadingG1 = false;
          this.isLoadingG2 = true;

          this.graficoAtual = "Compradores Participantes";
          page = await findGraficoComprador(1, {});
          this.elements = page.elements;          
          this.grafico2.series = this.elements.map(item => item.quantidade);
          this.grafico2.chartOptions.labels = this.elements.map(item => item.descricao); 
          this.$refs.grafico2.updateOptions({
            ...this.grafico2.chartOptions,
            series: this.grafico2.series
          }, true);

          this.isLoadingG2 = false;
          this.isLoadingG3 = true;

          this.graficoAtual = "Demandas criadas x Match Realizado";
          page = await findGraficoDemandaVsMatch(1, {});
          this.elements = page.elements;          
          this.grafico3.series = [
            {
              name: 'Criadas',
              data: this.elements.map(item => item.qtdDemandas)
            }, 
            {
              name: 'Match Realizado',
              data: this.elements.map(item => item.qtdMatchs)
            }            
          ];
          this.grafico3.chartOptions.xaxis.categories = this.elements.map(item => item.anoMes); 
          this.$refs.grafico3.updateOptions({
            ...this.grafico3.chartOptions,
            series: this.grafico3.series
          }, true);

          this.isLoadingG3 = false;
        }
      } catch (err) {
        if (err instanceof ApiError) {
          console.log("Erro ao carregar gráfico: " + this.graficoAtual + ". " +err.message);
        } else {           
          console.log("Erro ao carregar gráfico: " + this.graficoAtual + ". " +err.message);
        }
      } finally {
        this.isLoadingG1 = false;
        this.isLoadingG2 = false;
        this.isLoadingG3 = false;
      }
    },
  }
}
</script>
<style scoped lang="scss">
/*.page {
  border:2px solid red;
}*/
.page .is-mobile {
  .content {
    overflow: auto;
    max-height: calc(100vh - 60px);
  }

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 97px);
    }
  }
}

.page .content {
  /*border:2px solid green;*/
  overflow: auto;
  max-height: calc(100vh - 50px);
  width: 100%;
  flex: 1;

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 157px);
    }
  }
}
.rounded-border {
  border-radius: 8px !important;
  border: 0px !important;
}
.titulo {
  font-size: 1em;
  font-weight: bold;
  color:#167ABC;
}
.sub-titulo {
  font-size: 0.8em;
}
.titulo-grafico {
  font-size: 1em;
  font-weight: bold;
  color:black;
}
.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ajuste conforme necessário */
}
</style>