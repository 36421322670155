<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          v-model="item.dialogChat" style="border-radius: 8px;"
          scrollable max-width="500px"
          persistent>
        <v-card class="d-flex flex-column">
          <v-card-title class="primary white--text">
            <v-row>
              <v-col cols="12" md="8">
                {{getTitle}}
              </v-col>
              <v-col cols="12" align="right" md="4">
                <v-btn text elevation="0" color="white" small @click="fecharChat()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider/>
          <v-card-text class="py-4 flex-grow-1" 
            style="height: 80%;">          
              <v-row justify="center">
                <v-col cols="12" md="12">
                  <div class="chat-box" ref="chatBox">
                    <div
                      v-for="(message, index) in messages"
                      :key="index"
                      class="message-container"
                    >
                      <div :class="['chat-message', message.type]">
                        <div class="message-nome-origem">{{ message.nomeOrigem }}</div>
                        <div class="message-content">{{ message.mensagem }}</div>
                        <div class="message-time">{{ message.dataCadastro | moment('DD/MM/YY HH:MM') }}</div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
            <v-text-field
              v-model="newMessage"
              label="Digite sua mensagem"
              @keyup.enter="envairMEnsagem()"
              :disabled="waitSend"
              dense
              :loading="waitSend"
            ></v-text-field>
            <v-btn rounded text elevation="0" color="green" small @click="envairMEnsagem()">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {findAll, countNaoLidas, update, create} from '../../../api/chat';
import {ApiError} from "../../../api/core";
import {mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: 'ChatMatch',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      waitSend: false,
      pollingNaoLidas: null,
      pollingMensagens: null,
      pollingTime: 3000,
      qtdNaoLidas: 0,
      continuaEscutando: true,
      messages: [],
      newMessage: "",
    }
  },  
  watch: {
    'item.dialogChat': {
      handler(newValue) {
        if (newValue) {
          this.buscaMensagens();
          this.pollingMensagens = setInterval(this.buscaMensagens, this.pollingTime);
        } else {
          clearInterval(this.pollingMensagens);
        }
      }
    },
  },  
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
    getTitle() {
      return this.nivelUsuarioLogado === 'FOR' ? this.item.demanda.comprador.nomeFantasia : this.item.fornecedor.nomeFantasia;
    }
  },  
  methods: {
    fecharChat(){
      this.item.dialogChat = false;
    },
    async envairMEnsagem() {
      try {
        this.waitSend = true;
        this.messages.push({
          id: null,
          idMatch: null,
          type: "sent",
          mensagem: this.newMessage,
          dataCadastro: null,
        });
        await create({
            idMatch: this.item.id,
            idOrigem: this.user.id,
            idDestino: this.nivelUsuarioLogado === 'FOR' ? this.item.demanda.comprador.id : this.item.fornecedor.id,
            mensagem: this.newMessage
          });
        this.newMessage = "";
        this.scrollToBottom();
      } catch (err) {
        this.error = err;
        this.elements = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Ocorreu um erro inesperado.`, {
            timeout: 6000
          })
        } 
      } finally {
        this.waitSend = false;
      }
    },
    async buscaMensagens(){
      if (this.item.dialogChat){
        try {
          const page = await findAll(1, {idMatch: this.item.id});
          this.messages = page.elements;
          await update(this.item.id);
          this.scrollToBottom();
        } catch (err) {
          this.error = err;
          this.elements = [];        
          if (err instanceof ApiError) {
            this.$toast.error(err.message, {
              timeout: 6000
            })
          } else {
            this.$toast.error(`Ocorreu um erro inesperado. ${err.message}`, {
              timeout: 6000
            })
          } 
        }
      }
    },
    async checaMensagemNaoLida(){
      const page = await countNaoLidas(this.item.id);
      this.item.qtdMensagensNaoLidas = page.dados.qtdMensagensNaoLidas;
      // if (this.item.qtdMensagensNaoLidas > 0){
      //   this.$emit('onMsgNaoLida',1);
      // }
    },
    scrollToBottom() {
      const container = this.$refs.chatBox;
      //console.log('chamou o scrollToBottom', container);
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 0);
      }
    },
  },
  created() {
    this.pollingNaoLidas = setInterval(this.checaMensagemNaoLida, this.pollingTime);
  },
  mounted() {
    this.scrollToBottom();
  },
  beforeDestroy() {
    clearInterval(this.pollingNaoLidas);
  }
};
</script>

<style scoped>
  .chat-box {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }

  /* Estilo geral das mensagens */
  .chat-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
  }

  /* Mensagens recebidas */
  .received {
    float: left;
    max-width: 90%;
    background-color: #e0e0e0;
    color: #333;
  }

  /* Mensagens enviadas */
  .sent {
    float: right;
    max-width: 90%;
    background-color: #2196f3;
    color: #fff;
  }

  /* Conteúdo da mensagem */
  .message-content {
    margin-bottom: 4px;
  }

  .message-nome-origem {
    margin-bottom: 4px;
    font-weight: bold;
    text-decoration: underline;
  }

  /* Tempo da mensagem */
  .message-time {
    font-size: 0.6rem;
    text-align: right;
  }
</style>
